/** Layout **/
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  main {
    flex: 1 0 auto;

    .container {
      display: table;
      position: absolute;
      top: 0;
      left: 0;
      height: 80%;
      width: 100%;
    }
  }
}
